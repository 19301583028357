import OverviewRoutes from './overview/Routes';
import OutletRoutes from './outlet/Routes';
import PlansRoutes from './plans/Routes';
import SettingsRoutes from './settings/Routes';
import TermsRoute from './terms/Routes';
import { Redirect, Switch, Route } from 'react-router-dom/cjs/react-router-dom';

function Routes() {
  return (
    <Switch>
      <Route path="/sp-manager/overview*">
        <OverviewRoutes />
      </Route>

      <Route path="/sp-manager/outlet*">
        <OutletRoutes />
      </Route>

      <Route path="/sp-manager/plans*">
        <PlansRoutes />
      </Route>

      <Route path="/sp-manager/settings*">
        <SettingsRoutes />
      </Route>

      <Route path="/sp-manager/terms-and-conditions*">
        <TermsRoute />
      </Route>

      <Redirect exact from="/sp-manager" to="/sp-manager/overview" />
    </Switch>
  );
}

export default Routes;
