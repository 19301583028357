import { Button, DatePicker, Input, Popover, Tooltip } from 'antd';
import {
  CaretDownOutlined,
  CloseCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import PopoverContent from './PopoverContent';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDateFormat from '@/hooks/useDateFormat';
import useCalendarRange from '@/hooks/useCalendarRange';
import pxToRem from '@/common/pxToRem';

function useScript({ onRun = () => {} }) {
  const dateFormat = useDateFormat('day');
  const finalFormat = useDateFormat();
  const monthFormat = useDateFormat('shortCompetency');

  const {
    calendarType,
    startDate,
    endDate,
    setCalendarType,
    setStartDate,
    setEndDate,
  } = useCalendarRange();

  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);

  function handleDatesChange(dates) {
    if (Array.isArray(dates) && dates.length) {
      const [start, end] = dates;
      setStart(start.format(finalFormat));

      if (calendarType === 'month') {
        setEnd(end.endOf('month').format(finalFormat));
      } else {
        setEnd(end.format(finalFormat));
      }
    } else {
      setStart(null);
      setEnd(null);
    }
  }

  function run() {
    setStartDate(start);
    setEndDate(end);
    if (typeof onRun === 'function') {
      onRun([start, end, calendarType]);
    }
  }

  return {
    calendarType,
    setCalendarType,
    start,
    end,
    handleDatesChange,
    run,
    dateFormat,
    monthFormat,
  };
}

function DatePickerComponent({
  loading,
  disableRunBtn,
  allowAbort = false,
  onRun = () => {},
  onAbort = () => {},
}) {
  const { t } = useTranslation();

  const {
    start,
    end,
    handleDatesChange,
    calendarType,
    setCalendarType,
    run,
    dateFormat,
    monthFormat,
  } = useScript({
    onRun,
  });

  return (
    <Input.Group
      compact
      style={{ marginTop: 0, display: 'flex', flexWrap: 'nowrap' }}
      data-test-id="date-picker"
    >
      <Popover
        placement="bottomLeft"
        title={false}
        content={
          <PopoverContent
            onChange={handleDatesChange}
            calendarType={calendarType}
            setCalendarType={setCalendarType}
          />
        }
        trigger="click"
      >
        <Button
          icon={<CaretDownOutlined style={{ fontSize: pxToRem(12) }} />}
        />
      </Popover>

      <DatePicker.RangePicker
        allowClear={false}
        value={[dayjs(start), dayjs(end)]}
        format={calendarType === 'month' ? monthFormat : dateFormat}
        onChange={handleDatesChange}
        picker={calendarType}
        style={{
          width: calendarType === 'month' ? 200 : 220,
          height: 32,
        }}
        suffixIcon={false}
      />

      {allowAbort ? (
        <Tooltip title={t('workspace.reload.data')} placement="bottomRight">
          <Button
            onClick={(event) => {
              event.preventDefault();
              loading ? onAbort() : run();
            }}
            icon={loading ? <CloseCircleOutlined /> : <ReloadOutlined />}
            style={
              disableRunBtn
                ? undefined
                : { fontWeight: 600, color: loading ? '#FE725C' : '#338FCC' }
            }
            disabled={disableRunBtn}
          >
            {loading ? t('common.cancel') : t('common.applay')}
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title={t('workspace.reload.data')} placement="bottomRight">
          <Button
            onClick={() => run()}
            loading={loading}
            icon={<ReloadOutlined />}
            style={
              disableRunBtn ? undefined : { fontWeight: 600, color: '#338FCC' }
            }
            disabled={disableRunBtn}
          >
            {t('common.applay')}
          </Button>
        </Tooltip>
      )}
    </Input.Group>
  );
}

export default DatePickerComponent;
