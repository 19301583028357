import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const Home = lazy(() => import('./home'));
const Upsert = lazy(() => import('./upsert'));
const Simulators = lazy(() => import('./simulators/home'));
const SimulatorResult = lazy(() => import('./simulators/result'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/lighthouse/lia/templates`}
        title="LIA Templates"
        component={Home}
      />
      <PrivateRoute
        exact
        path={`/lighthouse/lia/templates/create`}
        title="LIA Templates Create"
        component={Upsert}
      />
      <PrivateRoute
        exact
        path={`/lighthouse/lia/templates/:id/edit`}
        title="LIA Templates Edit"
        component={Upsert}
      />
      <PrivateRoute
        exact
        path={`/lighthouse/lia/templates/:id/simulators`}
        title="LIA Templates Simulators"
        component={Simulators}
      />
      <PrivateRoute
        exact
        path={`/lighthouse/lia/templates/:id/simulators/:simulatorId/results`}
        title="LIA Templates Simulator Result"
        component={SimulatorResult}
      />
    </>
  );
}

export default Routes;
