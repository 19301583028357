import { useTranslation } from 'react-i18next';
import mapFromObject from '@/common/mapFromObject';
import dayjs from 'dayjs';

export const languages = mapFromObject({
  'es-ES': {
    day: 'DD/MM/YYYY',
    month: 'MM/YYYY',
    dayMonth: 'DD/MMM',
    competency: 'MMMM/YYYY',
    shortCompetency: 'MMM/YYYY',
    dayHour: 'DD/MM/YYYY, HH:mm',
    hour: 'HH',
    hourMin: 'HH:mm',
  },
  'pt-BR': {
    day: 'DD/MM/YYYY',
    month: 'MM/YYYY',
    dayMonth: 'DD/MMM',
    competency: 'MMMM/YYYY',
    shortCompetency: 'MMM/YYYY',
    dayHour: 'DD/MM/YYYY [às] HH:mm',
    hour: 'HH',
    hourMin: 'HH:mm',
  },
  'en-US': {
    day: 'MM/DD/YYYY',
    month: 'MM/YYYY',
    dayMonth: 'MMM/DD',
    competency: 'MMMM/YYYY',
    shortCompetency: 'MMM/YYYY',
    dayHour: 'MM/DD/YYYY, hh:mm a',
    hour: 'hh a',
    hourMin: 'hh:mm a',
  },
});

export function dateFormatter({ date, format }) {
  if (!format) {
    return date;
  }

  if (format.toLowerCase().startsWith('hh')) {
    const [hours, minutes] = date.split(':');

    return dayjs()
      .set('hours', parseInt(hours || 0))
      .set('minutes', parseInt(minutes || 0))
      .format(format);
  }

  const formatted = dayjs(date);
  if (formatted.isValid()) {
    return formatted.format(format);
  }

  return date;
}

export function useDateFormats() {
  const { i18n } = useTranslation();
  return languages.get(i18n.resolvedLanguage);
}

function useDateFormat(type) {
  const { i18n } = useTranslation();
  return languages.get(i18n.resolvedLanguage)[type] ?? 'YYYY-MM-DD';
}

export default useDateFormat;
