import React from 'react';
import Routes from './Routes';
import { useAuth } from './context/AuthContext';
import { LoadingSpinner } from '@/components';
import { ConfigProvider } from 'antd';
import Modals from './modals';
import ptBR from 'antd/lib/locale/pt_BR';
import esES from 'antd/lib/locale/es_ES';
import enUS from 'antd/lib/locale/en_US';
import { useTranslation } from 'react-i18next';
import tracking from '@/services/tracking';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localeData from 'dayjs/plugin/localeData';
import duration from 'dayjs/plugin/duration';
import objectSupport from 'dayjs/plugin/objectSupport';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/es';
import 'dayjs/locale/en-gb';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.extend(duration);
dayjs.extend(objectSupport);
dayjs().localeData();

function App() {
  const { loading } = useAuth();
  const { i18n } = useTranslation();

  const languages = {
    'pt-BR': ptBR,
    'es-ES': esES,
    'en-US': enUS,
  };

  const dateLanguages = {
    'pt-BR': 'pt-br',
    'es-ES': 'es',
    'en-US': 'en-gb',
  };

  dayjs.locale(dateLanguages[i18n.resolvedLanguage]);

  tracking.init();

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <ConfigProvider locale={languages[i18n.resolvedLanguage]}>
      <Modals />
      <Routes />
    </ConfigProvider>
  );
}

export default App;
