import { useMemo, useState } from 'react';
import { Button, Divider, Menu, Radio, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import mapFromObject from '@/common/mapFromObject';
import dayjs from 'dayjs';
import { CalendarOutlined, CaretDownOutlined } from '@ant-design/icons';
import { makeStyles } from '@material-ui/core/styles';
import VerticalSpace from '@/components/VerticalSpace';

const { Text } = Typography;

const useStyles = makeStyles(() => ({
  menu: {
    border: 'none',
    '& > .ant-menu-item': {
      height: '30px',
      lineHeight: '30px',
      paddingLeft: 4,
    },
  },
  groupTitle: { textTransform: 'uppercase', fontSize: '0.7rem' },
  optionsBtn: {
    paddingLeft: 0,
    marginTop: -16,
    fontWeight: 500,
    fontSize: '0.8rem',
  },
}));

function useScript({ onChange, calendarType, setCalendarType }) {
  const { t } = useTranslation();

  const [showOpts, setShowOpts] = useState(false);

  const menuDateItemsMap = useMemo(
    () =>
      mapFromObject({
        0: t('datepicker.this.week'),
        1: t('datepicker.last.7days'),
        2: t('datepicker.last.15days'),
      }),
    [t]
  );

  const menuMonthItemsMap = useMemo(
    () =>
      mapFromObject({
        3: t('datepicker.this.month'),
        4: t('datepicker.this.year'),
        5: t('datepicker.last.month'),
        6: t('datepicker.last.3months'),
        7: t('datepicker.last.6months'),
        8: t('datepicker.last.12months'),
      }),
    [t]
  );

  const rangesMap = useMemo(
    () =>
      mapFromObject({
        0: [dayjs().startOf('week'), dayjs()],
        1: [dayjs().subtract(6, 'days'), dayjs()],
        2: [dayjs().subtract(14, 'days'), dayjs()],
        3: [dayjs().startOf('month'), dayjs().endOf('month')],
        4: [dayjs().startOf('year'), dayjs().endOf('month')],
        5: [
          dayjs().subtract(1, 'months').startOf('month'),
          dayjs().subtract(1, 'months').endOf('month'),
        ],
        6: [
          dayjs().subtract(3, 'months').startOf('month'),
          dayjs().endOf('month'),
        ],
        7: [
          dayjs().subtract(6, 'months').startOf('month'),
          dayjs().endOf('month'),
        ],
        8: [
          dayjs().subtract(12, 'months').startOf('month'),
          dayjs().endOf('month'),
        ],
      }),
    []
  );

  const menuItems = useMemo(() => {
    const list =
      calendarType === 'date'
        ? [...menuDateItemsMap, ...menuMonthItemsMap]
        : [...menuMonthItemsMap];

    return list.map(([key, value]) => ({
      key,
      label: (
        <Space>
          <CalendarOutlined />
          <Text>{value}</Text>
        </Space>
      ),
    }));
  }, [calendarType, menuDateItemsMap, menuMonthItemsMap]);

  function handleMenuClick(key) {
    if (typeof onChange === 'function') {
      return onChange(rangesMap.get(key));
    }
  }

  function handleShowOptions() {
    setShowOpts(!showOpts);
  }

  return {
    calendarType,
    menuItems,
    handleMenuClick,
    setCalendarType,
    handleShowOptions,
    showOpts,
  };
}

function Content({ onChange, calendarType, setCalendarType }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { menuItems, handleMenuClick, handleShowOptions, showOpts } = useScript(
    {
      onChange,
      calendarType,
      setCalendarType,
    }
  );

  return (
    <VerticalSpace style={{ width: 220 }} size={16}>
      <VerticalSpace size={4}>
        <Text className={classes.groupTitle} type="secondary">
          {t('datepicker.often')}
        </Text>

        <Menu
          items={menuItems}
          selectedKeys={[]}
          className={classes.menu}
          style={{ border: 'none' }}
          onClick={({ key }) => handleMenuClick(key)}
        />
      </VerticalSpace>

      <Divider style={{ margin: 0 }} />

      <VerticalSpace>
        <Button
          type="link"
          className={classes.optionsBtn}
          onClick={() => handleShowOptions()}
          fontWeight={600}
        >
          <Space size={80}>
            {t('common.advanced.options')}
            <CaretDownOutlined rotate={showOpts ? 180 : 0} />
          </Space>
        </Button>

        {showOpts ? (
          <VerticalSpace>
            <Text className={classes.groupTitle} type="secondary">
              {t('datepicker.select')}
            </Text>

            <Radio.Group
              value={calendarType}
              buttonStyle="solid"
              style={{ boderRadius: 4 }}
              onChange={({ target }) => setCalendarType(target.value)}
            >
              <Radio.Button value="month">{t('datepicker.view')}</Radio.Button>
              <Radio.Button value="date">{t('datepicker.date')}</Radio.Button>
            </Radio.Group>
          </VerticalSpace>
        ) : null}
      </VerticalSpace>
    </VerticalSpace>
  );
}

export default Content;
